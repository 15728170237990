export interface WikiColorInterface {
    color1: string;
    color2: string;
    color3: string;
    color4: string;
    color5: string;
    color6: string;
    color7: string;
    color8: string;
    color9: string;
    color10: string;
    color11: string;
    color12: string;
}

export const updateTheme = (settings: WikiColorInterface) => {
    let barColor = settings.color1 ? `${settings.color1}` : '';
    let fontColor = settings.color2 ?`${settings.color2}` : '';
    let activeColor =settings.color3 ? `${settings.color3}` : '';
    let activeFontColor = settings.color4 ?`${settings.color4}` : '';
    let hoverColor = settings.color5 ?`${settings.color5}` : '';
    let hoverFontColor = settings.color6 ?`${settings.color6}` : '';
    let elementColor = settings.color7 ?`${settings.color7}` : '';
    let elementFontColor = settings.color8 ?`${settings.color8}` : ''
    let elementActiveColor = settings.color9 ?`${settings.color9}`: '';
    let elementActiveTextColor = settings.color10 ?`${settings.color10}`: '';
    let elementHoverColor = settings.color11 ?`${settings.color11}`: '';
    let elementHoverTextColor = settings.color12 ?`${settings.color12}`: '';

    let style = document.documentElement.style

    style.setProperty("--nav-bar-color", barColor);
    style.setProperty("--nav-bar-font-color", fontColor);
    style.setProperty("--nav-bar-active-color", activeColor)
    style.setProperty("--nav-bar-active-font-color", activeFontColor)
    style.setProperty("--nav-bar-hover-color", hoverColor)
    style.setProperty("--nav-bar-hover-font-color", hoverFontColor)
    style.setProperty("--element-color", elementColor)
    style.setProperty("--element-font-color", elementFontColor)
    style.setProperty("--element-active-color", elementActiveColor)
    style.setProperty("--element-active-font-color", elementActiveTextColor)
    style.setProperty("--element-hover-color", elementHoverColor)
    style.setProperty("--element-hover-font-color", elementHoverTextColor)

}