

import httpCodes from 'http-status-codes';
import { EnvVariable } from '../types/settings.types';
import { ApiService } from "./api-service";
import { SettingsService } from './settings.service';

export interface ApplicationSettings {
    env: EnvVariable[],
}

export class EnvironmentVarService {


    public async load(): Promise<ApplicationSettings> {

        let settings = new SettingsService();

        let path = ApiService.cleanUrl(`/env`);

        if (process.env.REACT_APP_DEV_MODE?.trim() === "true") {
            path = ApiService.cleanUrl(`${settings.server}env`);
        }

        //The run so early in the startup of the app it does not use the ApiService.
        const requestOptions: RequestInit = {
            method: 'get'
        }
        let f = await fetch(path, requestOptions);
        if (f.status === httpCodes.OK) {
            let rep = f as Response;
            let result = await rep.json() as ApplicationSettings;
            return result;
        }
        // }
        return {
            env: new Array<EnvVariable>(),
        }
    }



}