
import { isExpired } from "react-jwt";
import { AuthenticationService } from "@liminil/partners-sdk";
import { UserCache } from "../caches";
import { SettingsService } from "../settings.service";
import { ApiService } from "../api-service";

export interface DataStoredInToken {
    exp: number;
    oid: string;
    email?: string;
    preferred_username?: string
    name: string;
    iss?: string;
}

export function parseJwt (token: string) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}

export class JwtAuthService  {
    
    private _loginService!: AuthenticationService;
    private _settingsService: SettingsService;

    protected get token() {
        return "";
    }

    protected get settingsService() {
        return this._settingsService;
    }

    private _apiService: ApiService;

    protected get apiService() {
        return this._apiService;
    }

    constructor() {
        this._settingsService = new SettingsService();
        this._apiService = new ApiService();
        this._loginService = new AuthenticationService({
            url: this.settingsService.authApi,
            api: this.apiService
        });
    }

    public async authenticate(userName: string, password: string) {

        let response = await this._loginService.authenticate(this.settingsService.jwtClientId, userName, password, '');
        if (response) {
            UserCache.idToken = response.access_token;
        }
        return response;
    }

    public async nwLogin(token: string) : Promise<string> {
        let response = await this._loginService.nwLogin(token);
        if (response) {
            UserCache.idToken = response.access_token;
            return response.access_token;
        }
        return "";
    }

    public async silentLogin() : Promise<string> {

        let token = UserCache.idToken
        let response = await this._loginService.refreshToken(this.settingsService.jwtClientId, token);
        if (response) {
            UserCache.idToken = response.access_token;
            return response.access_token;
        }
        return "";
    }

    public static isAuthenticated(): boolean {
        let token = UserCache.idToken;
        let valid = !isExpired(token);
        return valid;
    }

    public static logout() {
        UserCache.clear();
        
    }

    public async forgotPassword(email: string) {
        return await this._loginService.forgotPassword(email, '')
    }



    public async getByResetKey(resetKey: string) {
        return await this._loginService.getByResetKey(resetKey, this.token);
    }
}