import { ConcurrencyError, ForbiddenError, FormKeys, IApiFileService, NotFoundError, SatchelSdkError, UnauthorizedError } from "@liminil/common-sdk";
import { handleDates, reviveDateTime } from "../common/date-utils";
import httpCodes from 'http-status-codes';
import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { JwtAuthService, parseJwt } from "./authentication";
import { Buffer } from 'buffer';

// @ts-ignore
window.Buffer = Buffer;

export class ApiService implements IApiFileService {

    private _headers: any;

    private toBuffer(ab: ArrayBuffer): Buffer {
        var buf = Buffer.alloc(ab.byteLength);
        var view = new Uint8Array(ab);
        for (var i = 0; i < buf.length; ++i) {
            buf[i] = view[i];
        }
        return buf;
    }

    public static cleanUrl(url: string): string {
        let pos = url.indexOf("://");
        let prefix = url.substring(0, pos + 3);
        let rest = url.substring(pos + 3);
        while (rest.indexOf("//") !== -1) {
            rest = rest.replace("//", "/");
        }
        return prefix + rest;
    }

    public async put(url: string, token: string, data: any): Promise<any> {
        return await this.makeCall(url, "put", token, data);
    }
    public async post(url: string, token: string, data: any): Promise<any> {
        return await this.makeCall(url, "post", token, data);
    }
    public async get(url: string, token: string): Promise<any> {
        return await this.makeCall(url, "get", token, null);
    }
    public async delete(url: string, token: string, data: any): Promise<any> {
        return await this.makeCall(url, "delete", token, data);
    }

    public static deserialize(data: string): any {
        return JSON.parse(data, reviveDateTime);
    }

    private async getToken() {
        let token = "";

        let isValid = JwtAuthService.isAuthenticated();
         if (isValid) {
             let service = new JwtAuthService();
             token = await service.silentLogin();
        }
        
        //let data =  parseJwt(token) ;
       

        return token;
    }

    public setHeaders(value: any) {
        this._headers = value;
    }

    private async makeCall(url: string, method: string, token: string, data: any): Promise<any> {

       // let body = (data) ? JSON.stringify(data) : null;

         url = ApiService.cleanUrl(url);

        url = encodeURI(url);


        const requestOptions: AxiosRequestConfig = {
            method: method,
            url: url
        };

        if (!token) {
            token = await this.getToken();
        }

        if (token && token !== "") {
            requestOptions.headers = { "Authorization": `Bearer ${token}`, "Content-Type": "application/json"  };
        } else {
            requestOptions.headers = { "Content-Type": "application/json" };
        }

        //   console.log(`${method}: URL: ${url} - Json: ${requestOptions.body}`)

        let error: Error | null = null;

        const axiosClient = axios.create(requestOptions);
        axiosClient.interceptors.response.use(originalResponse => {
            handleDates(originalResponse.data);
            return originalResponse;
        });

        try {
            let response = await axiosClient.request({ ...requestOptions, data: data ? data : undefined });
            if (response !== undefined) {
                return response.data;
            }
        } catch (ex) {
            let axiosError = ex as AxiosError;
            let response = axiosError.response;
            if (response) {
                let text = response.data;
                switch (response.status) {
                    case httpCodes.NOT_FOUND: error = new NotFoundError(text); break;
                    case httpCodes.INTERNAL_SERVER_ERROR: error = new SatchelSdkError(text); break;
                    case httpCodes.CONFLICT: error = new ConcurrencyError(text); break;
                    case httpCodes.UNAUTHORIZED: error = new UnauthorizedError(text); break;
                    case httpCodes.FORBIDDEN: error = new ForbiddenError(text); break;
                    case httpCodes.BAD_REQUEST: error = new SatchelSdkError(text); break;
                }
            } else {
                console.log(`Result: URL: ${url} - Error: ${ex}`);
                throw new SatchelSdkError(ex);
            }

            if (error)
                throw error;
            return "";

        }


    }

    public async getFile(url: string, token: string): Promise<Buffer> {

        url = ApiService.cleanUrl(url);

        const requestOptions: RequestInit = {
            method: "get",
        };

        if (!token) {
            token = await this.getToken();
        }

        if (token && token !== "") {
            requestOptions.headers = { "Authorization": `Bearer ${token}`, "Content-Type": "application/json" };
        } else {
            requestOptions.headers = { "Content-Type": "application/json" };
        }

        let f = await fetch(url, requestOptions);
        if (f !== undefined) {
            switch (f.status) {
                case httpCodes.OK: {
                    let rep = f as Response;
                    let arrayBuffer = await rep.arrayBuffer();
                    let buffer = this.toBuffer(arrayBuffer)

                    return buffer;
                }
                case httpCodes.NOT_FOUND: throw new NotFoundError(f.statusText)
                case httpCodes.INTERNAL_SERVER_ERROR: throw new SatchelSdkError(f.statusText);
                case httpCodes.CONFLICT: throw new ConcurrencyError(f.statusText);
                case httpCodes.UNAUTHORIZED: throw new UnauthorizedError(f.statusText);
                case httpCodes.FORBIDDEN: throw new ForbiddenError(f.statusText);
            }
        }

        return Buffer.from('');

    }

    //TODO: needs to be updated to Axios when we need it.
    public async uploadFile(url: string, token: string, fileKey: string, file: File, method?: string, additionalData?: FormKeys[]): Promise<any> {

        url = ApiService.cleanUrl(url);

        let formData = new FormData();
        formData.append(fileKey, file, file.name);
        if (additionalData) {
            additionalData.forEach(k => {
                formData.append(k.key, k.value);
            })
        }

        const requestOptions: RequestInit = {
            method: method ? method : "post",
            body: formData
        };

        if (!token) {
            token = await this.getToken();
        }


        if (token && token !== "") {
            requestOptions.headers = { "Authorization": `Bearer ${token}` };
        }

        try {
            let f = await fetch(url, requestOptions);
            if (f !== undefined) {
                let rep = f as Response;
                let text = await rep.text();
                switch (f.status) {

                    case httpCodes.OK: {
                        let json = ApiService.deserialize(text);;
                        return json;
                    }
                    case httpCodes.NOT_FOUND: throw new NotFoundError(text)
                    case httpCodes.INTERNAL_SERVER_ERROR: throw new SatchelSdkError(text);
                    case httpCodes.CONFLICT: throw new ConcurrencyError(text);
                    case httpCodes.UNAUTHORIZED: throw new UnauthorizedError(text);
                    case httpCodes.FORBIDDEN: throw new ForbiddenError(text);
                }
            }
            return null;
        } catch (ex) {
            console.log(`Result: URL: ${url} - Error: ${ex}`);
            throw new SatchelSdkError(ex);
        }

    }

}