import React, { useEffect, useRef, useState } from 'react';
import './App.scss';
import '@fortawesome/fontawesome-free/css/all.css';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import AppHeader from './components/app-header/app-header';
import loadable from '@loadable/component';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { CurrentUserState, getCurrentUser } from './redux/c-user.slice';
import RequireAuth from './components/authentication/auth-validation';
import { useSelector } from 'react-redux';
import { AppState } from './redux/store';
import { WikiColorInterface, updateTheme } from './common/utils/style-utils';
import Footer from './components/footer/footer';
import { getVersion } from './redux/settings-slice';
import { IntegratorRole } from '@liminil/partner-types';
import { NavBarState, NavPageMode, setLayoutMode, setSideMenu, setSideMenuOpen } from './redux/nav-bar-slice';
import MessageBox from './components/message-box';
import { Sidebar } from 'primereact/sidebar';
// import AiQueryGenerator from '@liminil/ui-components';
import ChatApp from './components/chat';
const Home = loadable(() => import('./routes/home'));
const Signin = loadable(() => import('./routes/signin'));
const RootView = loadable(() => import('./routes/root'));
const SettinsView = loadable(() => import('./routes/settings'));
const ReaderView = loadable(() => import('./routes/reader/wh-reader'));
const DataBrowserView = loadable(() => import('./routes/browse'))
const MapFieldsView = loadable(() => import('./routes/map'))
const AddView = loadable(() => import('./routes/add'))
const ProfileView = loadable(() => import('./routes/profile'))
const DataEntry = loadable(() => import('./routes/entry'))
const SegmentsView = loadable(() => import('./routes/segment'))

const mql = window.matchMedia(`(min-width: 800px)`);

function App() {

  const dispatch = useDispatch<any>();
  const _currentUser = useSelector<AppState>(state => state.currentUser) as CurrentUserState;

  const _navbar = useSelector<AppState>(state => state.navbar) as NavBarState;
  const colors: WikiColorInterface = {
    color1: "#ffffff", // header background
    color2: "#ff0000", // font color
    color3: "#f5f5f5", // active color
    color4: "#000000", // active font color
    color5: "#c1c1c1", //hover color
    color6: "#c1c1c1", // menu hover
    color7: "#000000", // element color
    color8: "#ffffff",
    color9: "#D0A946",
    color10: "#000000",
    color11: "#c1c1c1",
    color12: "#000000"
  }

  useEffect(() => {
    if (_currentUser?.user) {
      updateTheme(colors);
    }
  }, [colors])


  useEffect(() => {
    dispatch(getCurrentUser());
    dispatch(getVersion());

  }, []);

  useEffect(() => {
    checkForLStoredSystemConfig();
    mql.onchange = (ev => {

      if (ev.matches) {
        dispatch(setLayoutMode("full"))
      } else {
        dispatch(setLayoutMode("mobile"))
      }
    });

    mql.addEventListener("error", (e) => {
      console.log(e);
    })

    if (mql.matches) {
      dispatch(setLayoutMode("full"))
    } else {
      dispatch(setLayoutMode("mobile"))
    }

  }, [])
  document.addEventListener('keydown', (e) => {
    if(e.ctrlKey && e.key === 'a'){
      setVisibleChat(true);
    }
  });


  const checkForLStoredSystemConfig = () => {
    const l = JSON.parse(localStorage.getItem("systemConfig") || "[]");
    if (l.length > 0) {
      return l;
    } else {
      const _defaultConfig: any[] = [
        {
          "systemName": "Remnant",
          "dataBlock": "DB-JYPO1UICFSRJJVMTBE",
          "dataTable": "DT-KYPKOETLAQ6AJ12W1V",
          "id": "DB-JYPO1UICFSRJJVMTBEDT-KYPKOETLAQ6AJ12W1V"
        },
        {
          "systemName": "AllanGray",
          "dataBlock": "DB-JYPO1UICFSRJJVMTBE",
          "dataTable": "DT-NJPU5BXPMD4AU4LCWC",
          "id": "DB-JYPO1UICFSRJJVMTBEDT-NJPU5BXPMD4AU4LCWC"
        },
        {
          "systemName": "Momentum",
          "dataBlock": "DB-JYPO1UICFSRJJVMTBE",
          "dataTable": "DT-9JKZ8T6QTCVBYD1KYK",
          "id": "DB-JYPO1UICFSRJJVMTBEDT-9JKZ8T6QTCVBYD1KYK"
        },
        {
          "systemName": "PPS",
          "dataBlock": "DB-JYPO1UICFSRJJVMTBE",
          "dataTable": "DT-1IXGNOEW4YR67Y83P7",
          "id": "DB-JYPO1UICFSRJJVMTBEDT-1IXGNOEW4YR67Y83P7"
        },
        {
          "systemName": "Sanlam",
          "dataBlock": "DB-JYPO1UICFSRJJVMTBE",
          "dataTable": "DT-ZXHD6SL88ZWE3EIU7A",
          "id": "DB-JYPO1UICFSRJJVMTBEDT-ZXHD6SL88ZWE3EIU7A"
        },
        {
          "systemName": "Sure",
          "dataBlock": "DB-JYPO1UICFSRJJVMTBE",
          "dataTable": "DT-C828LEUHDA6AFR45KU",
          "id": "DB-JYPO1UICFSRJJVMTBEDT-C828LEUHDA6AFR45KU"
        }
      ]
      localStorage.setItem("systemConfig", JSON.stringify(_defaultConfig));
    }
  }
  const [visibleChat, setVisibleChat] = React.useState<boolean>(false);

  return (
    <>
      <div className='app-container'>
        <Router>

          <div className='nav-menu-container'>

        {(window.self === window.top) ? <AppHeader /> : null}
            
          </div>
          <MessageBox />
          <div id="main-container" className='main-container'>

            <Routes>
              <Route path="/" element={<RootView />} />
              <Route path="/signin" element={<Signin />} />
              <Route path="/reader" element={<RequireAuth><ReaderView /></RequireAuth>} />\

              <Route path="/segments" element={<RequireAuth><SegmentsView /></RequireAuth>} />\
              <Route path="/add" element={<RequireAuth><AddView /></RequireAuth>} />
              <Route path="/entry" element={<RequireAuth><DataEntry /></RequireAuth>} />
              <Route path="/home" element={<RequireAuth><Home /></RequireAuth>} />
              <Route path="/map" element={<RequireAuth><MapFieldsView /></RequireAuth>} />
              <Route path="/profile" element={<RequireAuth><ProfileView /></RequireAuth>} />
              <Route path="/data-browse" element={<RequireAuth><DataBrowserView /></RequireAuth>} />
              <Route path="/settings" element={<RequireAuth><SettinsView /></RequireAuth>} />
            </Routes>

          </div>
          <Footer />
        </Router>


        <Sidebar  visible={visibleChat} style={{height: 500, width: 700, marginLeft: '45%', padding: 0}} position="bottom"  onHide={() => setVisibleChat(false)}>
          <ChatApp />
        </Sidebar>
      </div>
    </>
  );
}

export default App;