import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { EnvVariable } from "../types/settings.types";
import { EnvironmentVarService } from "../services/env-var.service";
import { ServerVersionService } from "../services/server-version.srvice";
import { ConfigurationData } from "@liminil/registration-sdk-client";


export interface SettingsState {
    readonly vars: EnvVariable[];
    readonly busy: boolean;
    readonly version: string;
    readonly releaseDate: string;
    readonly config?: ConfigurationData[]
}

export const initSettingsState: SettingsState = {
    vars: new Array<EnvVariable>(),
    busy: false,
    releaseDate:(new Date()).toJSON(),
    version: 'DEBUG'
}

export const getEnvVariables = createAsyncThunk(
    'settings/getEnvVariables',
    async (arg, { getState }) => {
        let service = new EnvironmentVarService();
        const result = await service.load();
        return result;
    }
)

export const getVersion = createAsyncThunk(
    'settings/getVersion',
    async (arg, { getState }) => {
        let service = new ServerVersionService();
        const result = await service.load();
        return result;
    }
)

const settingsModelSlice = createSlice({
    name: 'settings',
    initialState: initSettingsState,
    reducers: {
        updateSettings(state, action) {
            state.vars = action.payload.env;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getEnvVariables.fulfilled, (state, action) => {
            state.vars = action.payload.env;
        })
        builder.addCase(getVersion.fulfilled, (state, action) => {
            state.version = action.payload.version;
            state.releaseDate = action.payload.releaseDate;
        })
    }
})

export const { updateSettings } = settingsModelSlice.actions;
export default settingsModelSlice.reducer;