import { IntegratorUser } from "@liminil/partner-types";
import { BaseClientService } from "../../base-client.service";
import { UserCache } from "../../caches";
import {  UserService, } from '@liminil/partners-sdk';

export class ClientUserService extends BaseClientService {

    private _usersService: UserService;

    constructor() {
        super();
        this._usersService = new UserService({
            api: this.apiService,
            url: this.settingsService.authApi
        });
    }

    public async currentUser(): Promise<IntegratorUser | null> {

        let user = await this._usersService.getMe(this.token) as IntegratorUser;
        return user;
    

}

public async getMe(): Promise<IntegratorUser | null> {

    let idToken = UserCache.idToken;
    if (idToken) {
        let user = await this._usersService.getMe(idToken) as IntegratorUser;
        return user;
    } else {
        return null
        
    }
}

public static displayName(user?: IntegratorUser | null): string {
    if (user) {

        if (user.firstName && user.lastName)
            return `${user.firstName} ${user.lastName}`;
        return user.email ?? '';
    }
    return '';
}



}