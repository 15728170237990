import { createIntl, createIntlCache } from "react-intl"
import messageJson from '../translations.json';

interface languageFile {
    [key: string]: {
        [key: string]: string
    }
}

const cache = createIntlCache();
export const messages = messageJson as languageFile;

let int = createIntl(
    {
        locale: "en",
        messages: messages["en"]
    },
    cache
);

const translate = (id: string, values?: {}) => {
    return int.formatMessage({ id }, values);
}

export default translate;