import { useSelector } from "react-redux";
import { JwtAuthService } from "../../services/authentication";
import { AppState } from "../../redux/store";
import { CurrentUserState } from "../../redux/c-user.slice";
import { Navigate, useLocation } from "react-router-dom";


export interface AuthenticatedRouteProps {
    children: JSX.Element;
    roles?: string[];
}

export default function RequireAuth(props: AuthenticatedRouteProps) {

    let valid = JwtAuthService.isAuthenticated();
    const currentUser = useSelector<AppState>(state => state.currentUser) as CurrentUserState;
    let location = useLocation();

    if (!valid) {
        // Redirect them to the /signin page, but save the current location they were
        // trying to go to when they were redirected. This allows us to send them
        // along to that page after they login, which is a nicer user experience
        // than dropping them off on the home page.
        return <Navigate to="/signin" state={{ from: location }} />;
    }

    let user = currentUser.user;
    let roleValid = true;
    let restrictedToRoles = props.roles ? props.roles : [];
    if (user && restrictedToRoles.length > 0) {
        roleValid = false;
        let userRoles = (user.roles as string[]) as string[];
        if (userRoles) {
            for (let i = 0; i < restrictedToRoles.length; i++) {
                const role = restrictedToRoles[i];
                if (userRoles.includes(role)) {
                    roleValid = true;
                    break;
                }
            }
            if (!roleValid) {
                return <Navigate to="/signin" state={{ from: location }} />;
            }
        }
    }

    return props.children

}