import React, { useState } from 'react';
import { Button} from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';

interface MessageInputProps {
  onSendMessage: (messageText: string) => void;
}

const MessageInput: React.FC<MessageInputProps> = ({ onSendMessage }) => {
  const [text, setText] = useState('');

  const handleSend = () => {
  
      onSendMessage(text);
 
  };

  return (
    <div style={{  padding: 0, margin: 0, width: '100%' }}>
      {/* <InputTextarea
        value={text}
        placeholder='Describe your query here...'
        onChange={(e) => setText(e.target.value)}
        style={{ margin: 0,padding: 10, width: '100%' }}
      /> */}
      <Button outlined style={{width: '100%'}} onClick={handleSend}>Send</Button>
    </div>
  );
};

export default MessageInput;
