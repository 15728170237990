import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AppState } from "./store";
import { ClientUserService } from "../services/modules/users/client-user.service";
import { IntegratorUser } from "@liminil/partner-types";


export interface CurrentUserState {
    readonly user: IntegratorUser | null;
    readonly busy: boolean;
}

export const initCurrentUserState: CurrentUserState = {
    user: null,
    busy: false
}

export const getCurrentUser = createAsyncThunk(
    'user/getCurrentUser',
    async (arg, { getState }) => {
        const state = getState() as AppState;
        let currentUser = state.currentUser;
        if (!currentUser.user) {
            let service = new ClientUserService();
            const user = await service.getMe();
            return user;
        }
        return currentUser.user
    }
)



const currentUserModelSlice = createSlice({
    name: 'current-user-model',
    initialState: initCurrentUserState,
    reducers: {
        setCurrentUser(state, action) {
            state.user = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getCurrentUser.fulfilled, (state, action) => {
            state.user = action.payload
        })
    }
})

export const { setCurrentUser } = currentUserModelSlice.actions;
export default currentUserModelSlice.reducer;