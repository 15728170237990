import React, { useState } from "react";
import MessageInput from "./message-input";
import CodeEditor from "@uiw/react-textarea-code-editor";
import { ProgressSpinner } from "primereact/progressspinner";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Panel } from "primereact/panel";
import { set } from "date-fns";
import { Toolbar } from "primereact/toolbar";

let i = "";
export interface IMessage {
  id: string;
  text: string;
  timestamp: Date;
}

const ChatApp: React.FC = () => {
  const models = [
    {
      name: "@cf/mistral/mistral-7b-instruct-v0.1",
      description: "Best for getting explanations with the responses.",
    },
    {
      name: "@cf/meta/llama-2-7b-chat-int8",
      description: "Better for just getting copy and paste code snippets.",
    },
    {
      name: "@cf/meta/llama-2-7b-chat-fp16",
      description: "Better for just getting copy and paste code snippets.",
    }
  ];
  const personas = [
    {
      name: "SQL Statement",
      lang: "sql",
      model: "@cf/mistral/mistral-7b-instruct-v0.1",
      message:
        "you are an expert in writing mysql queries for mysql. you only reply with valid MYSQL syntax queries with no comments or extra information. if the topic is not something that you can respond with a MYSQL statement then just say 'I do not understand and need something i can sql-lize!' do not format your response in markdown, just raw code. do not add escape characters. for example if the prompt is 'table is menu_items and the filter is rows where _id is null' - your result should be 'select * from menu_items where _id is null' and SHOULD NOT BE 'select * from menu_items where _id is null'",
    },
    {
      name: "Mongo Pipeline",
      lang: "js",
      model: "@cf/mistral/mistral-7b-instruct-v0.1",
      message:
        "you are a mongodb specialist and you can take natural language and respond with ONLY valid mongo aggregation pipeline code with no comments or extra information. do not format your response in markdown, just raw code.do not add escape characters. do not explain the code at all EVER. JUST REPLY WITH CODE!!!",
    },
    {
      name: "Code Function",
      lang: "js",
      model: "@cf/mistral/mistral-7b-instruct-v0.1",
      message:
        "you are a modern javascript expert and you can take natural language and respond with ONLY valid javascript code with no comments or extra information. DO NOT EVER format your response in markdown, just raw code. do not EVER add the word 'javascript' to your response. do not explain the code at all EVER.",
    },
    // {
    //   name: "JavaScript - with Explanations",
    //   model: "@cf/mistral/mistral-7b-instruct-v0.1",
    //   lang: "js",
    //   message:
    //     "you are a modern javascript expert and you can take natural language and respond with well explained valid javascript code.",
    // },
  ];
  const [aiText, setAiText] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedPersona, setSelectedPersona] = useState<{
    name: string;
    message: string;
    lang: string;
    model: string;
  }>(personas[0]);

  const handleChangePersona = (e: any) => {
    // console.log(e.target.value);
    const persona = personas.find((p) => p.name === e.target.value);
    if (persona) {
      setSelectedPersona(persona);
      setAiText(null);
    }
  };
  const handleCopyCode = () => {
    if (aiText) {
      navigator.clipboard.writeText(aiText as string);
    }
  };
  const handleSendMessage = async (text: string) => {
    setAiText(null);
    const userToken = JSON.parse(localStorage.getItem('authentication-result') || '{}');
    // client side check for token
    if(!_b_(userToken.accessToken)){
      alert('You are not authorized to use this application. Please contact your administrator.');
      return;
    }
    const cachedmap = localStorage.getItem('cachedMap') || '{}';

    setLoading(true);
    setAiText("");
    let resp = await fetch("https://codeplant-ai.codeplant.workers.dev/", {
      method: "POST",
      headers: {
        Accept: "text/event-stream",
      },
      body: JSON.stringify({
        system: selectedPersona.message,
        prompt: `Here are the collections to work with to create the joined query: ${cachedmap}`,
        model: selectedPersona.model,
        token: userToken.accessToken // add token for server side check
      }),
    });
    const reader = resp.body?.getReader() as any;
    let decoder = new TextDecoder();

    try {
      setLoading(false);
      while (true) {
        const { done, value } = await reader.read();
        if (done) {
          console.log("Stream complete");
          break;
        }
        const text = decoder.decode(value, { stream: true });
        text.split("\n").forEach((line) => {
          if (!line) return; // Skip empty lines

          if (line.startsWith("data:")) {
            const data = line.replace("data: ", "");
            if (data == "[DONE]") {
              return;
            }
            i += JSON.parse(data).response;
            i = i.replace(/`/g, "");
            i = i.replace("javascript", "");
            i = i.replace(/`/g, "");
            setAiText(i);
          }
        });
      }
    } catch (err) {
      console.error("Stream reading error:", err);
    } finally {
      reader.releaseLock();
    }
  };

  const  _a_ = (token: string) => {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    
    return JSON.parse(jsonPayload) || null;
  }
  const _b_ = (token: string) => {
    
    const _check = _a_(token);
    if (_check && _check.app_displayname == atob('UG93ZXIgSW50ZWdyYXRvcg==')) {
      return true;
    }
    return false;
  }

  return (

    <div>
        {!aiText && <div>
            <Dropdown
              id="personaSelect"
              name="select"
              style={{width: '100%'}}
              value={selectedPersona.name}
              options={personas.map((persona) => persona.name)}
              onChange={handleChangePersona}
            >
              
            </Dropdown>
            <MessageInput onSendMessage={handleSendMessage} />
        </div>     } 


   

  
      {loading ? <ProgressSpinner  /> : null}
      {aiText ? (
        <div>
          <div className="col-md-10">
           {aiText !== null && <CodeEditor
              value={aiText}
              language={selectedPersona.lang}
              padding={20}
              style={{ fontSize: 22, width: "100%" }}
            />}
          </div>

          <div>
            <Button
              disabled={aiText === null}
              onClick={handleCopyCode}
              className="btn btn-outline"
              style={{ width: "100%" }}
            >
              Copy Code
            </Button>
          </div>
        </div>
      ) : null}
    </div>
    
  );
};

export default ChatApp;
