import { AuthenticationResult } from '@azure/msal-browser';
import { DataStore, DataStoreKeys } from '../data-store';

interface TokenStore {
    idToken: string
}

export enum AuthTypes {
    None = "none",
    MSAL = "msal",
    JWT = "jwt"
}

export class UserCache {

    public static set idToken(token: string) {
        let data: TokenStore = {
            idToken: token
        }
        DataStore.setData(DataStoreKeys.ID_TOKEN, data);
    }

    public static get idToken(): string {
        let tokenInfo = DataStore.getData(DataStoreKeys.ID_TOKEN) as TokenStore;
        if (tokenInfo) {
            return tokenInfo.idToken;
        }
        return "";
    }

    
    public static get authenticationResult(): AuthenticationResult {
        return DataStore.getData(DataStoreKeys.AUTHENTICATION_RESULT) as AuthenticationResult;
        
     }

    public static set authenticationResult(value: AuthenticationResult) {
        
        DataStore.setData(DataStoreKeys.AUTHENTICATION_RESULT, value);
    }

    public static clear() : void {
        DataStore.removeData(DataStoreKeys.ID_TOKEN);
        DataStore.removeData(DataStoreKeys.AUTHENTICATION_RESULT);
    }


}