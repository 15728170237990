import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Toast } from 'primereact/toast';
import { hideMessageBox, MessageBoxState } from '../redux/message-box.slice';
import { AppState } from '../redux/store';

export default function MessageBox() {

    const dispatch = useDispatch()
    const toast = useRef<Toast>(null);

    const onClose = () => {

        dispatch(hideMessageBox(null));
    }

    const onClick = () => {
        toast.current?.clear()
        dispatch(hideMessageBox(null));
    }

    const messageState = useSelector<AppState>(state => state.messageBox) as MessageBoxState;

    useEffect(() => {
        if (toast && toast.current) {
            if (messageState.visible) {
                toast.current?.show({ severity: messageState.messageType, summary: messageState.title, detail: messageState.message, life: messageState.duration ? messageState.duration : 3000, sticky: messageState.sticky });
            } else {
                toast.current?.clear();
            }
        }
    }, [messageState])

    return (
        <Toast ref={toast} onRemove={onClose}  onClick={onClick} position={messageState.position as any}/>
    );


}
