import { Button } from "primereact/button";
import { useEffect, useState } from "react";
import { IntegratorUser } from "@liminil/partner-types";
import { UserCache } from "../../services/caches";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { AppState } from "../../redux/store";
import { CurrentUserState } from "../../redux/c-user.slice";
import { ClientUserService } from "../../services/modules/users/client-user.service";



export interface UserLoginClick {
    (e: any | undefined): void;
}

export interface UserProfileComponentProps {
    onLogout: UserLoginClick;
    onClose: () => void;
    name: string;
}

export default function UserProfileComponent(props: UserProfileComponentProps) {

    const [_userName, setUserName] = useState<string>('');
    const navigate = useNavigate();
    const _currentUser = useSelector<AppState>(state => state.currentUser) as CurrentUserState;

    let name = ClientUserService.displayName(_currentUser?.user)

    useEffect(() => {
        setUserName(props.name)
    }, [props.name])

    const logout = (e: any) => {

        UserCache.clear();
        props.onLogout(e);
        navigate("/signin")

    }

    return (
        <div className='grid'>
            <div className='col-10 '>
                <div className="lim-profile p-mr-2" id="current-user" title={_currentUser.user?.email}>{name}</div>

            </div>
            <div className='col-2'>
                <Button icon="pi pi-sign-out" className="lim-button p-mr-2 sign-out-button" onClick={logout}></Button>
            </div>


        </div>
    )

}

