import { useSelector } from "react-redux"
import { AppState } from "../../redux/store"
import { SettingsState } from "../../redux/settings-slice"
import { formatDate } from "../../common/date-utils";
import './footer.scss'

export default function Footer() {

  const _settings = useSelector<AppState>(state => state.settings) as SettingsState;

  let version = `${_settings.version} : ${formatDate(_settings.releaseDate)}`;

  return (

    <div className="lim-footer" >
      <div style={{position: 'absolute', bottom: '25px', left: '-20px', margin: '0px', padding: '0px', zIndex: -1}}>  
      <img src="/logo.svg" alt="logo" width="200"/>
      </div>

      <div className="p-grid p-align-center footer-version">{version}</div>
    </div>

  )

}
