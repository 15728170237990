import * as React from 'react';

export enum IconTypes {
    Cancel = 'Cancel',
    Delete = 'Delete',
    Edit = 'Edit',
    Home = "HomeIcon",
    Menu = 'Menu',
    New = 'New',
    Save = 'Save',
    Settings = 'Settings',
    Sync = 'Sync',
    User = 'User'
}

export interface IOptions {
    iconType: IconTypes;
    size?: number;
    color?: string;
    spin?: boolean;
}

export const iconTypeToPrimeIcon = (value: IconTypes) => {
    switch (value) {
        case IconTypes.Cancel: return 'fas fa-times';
        case IconTypes.Delete: return 'fas fa-trash';
        case IconTypes.Edit: return 'fas fa-pencil';
        case IconTypes.Home: return 'fas fa-home';
        case IconTypes.Menu: return 'fas fa-bars';
        case IconTypes.New: return 'fas fa-plus';
        case IconTypes.Save: return 'fas fa-check';
        case IconTypes.Settings: return 'fas fa-cog';
        case IconTypes.Sync: return 'fas fa-rotate';
        case IconTypes.User: return 'fas fa-user';
    }
}

