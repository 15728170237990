import { createSlice } from "@reduxjs/toolkit";
import { MenuItem } from "primereact/menuitem";
import { BreadcrumbInfo } from "../types/breadcrumbs.types";
import translate from "../common/translate";

export interface HeaderAction extends MenuItem {
    path?: string;
    items?: HeaderAction[];

}

export enum NavPageMode {
    Normal = "normal"
}

export interface SideMenuState {
    docked: boolean;
    open: boolean;
}


export interface NavBarState {
    readonly visible: boolean;
    readonly actions: HeaderAction[],
    readonly mode: "full" | "mobile",
    readonly breadcrumbs: BreadcrumbInfo[]
    readonly activeIndex: number
    readonly sideMenu: SideMenuState;
}

export const initNavBarState: NavBarState = {
    visible: false,
    actions: new Array<HeaderAction>(),
    mode: "full",
    breadcrumbs: new Array<BreadcrumbInfo>(),
    activeIndex: 0,
    sideMenu: {
        docked: true,
        open: false
    },
}

const pathToTitle = (segment: string) => {
    let value =  decodeURI(segment)
    return value.replace(/-/g, "_");

}

const navBarSlice = createSlice({
    name: 'nav-bar-model',
    initialState: initNavBarState,
    reducers: {
        setBreadcrumbs(state, action) {

            let location = window.location.pathname;
            let crumbs = new Array<BreadcrumbInfo>();

            let parts = location.split("/")
            let path = "";
            for (let i = 0; i < parts.length; i++) {
                const segment = parts[i];
                if (segment) {
                    path = `${path}/${segment}`;
                    if (!segment.match(/([0-9a-zA-Z]{32})/)) {
                        let title = pathToTitle(segment);
                        if (title) {
                            crumbs.push({
                                path: path,
                                label: title
                            })
                        }
                    }
                }
            }
            if (Array.isArray(action.payload))
                crumbs.push(...action.payload)

            state.breadcrumbs = crumbs;
        },
        setHeaderMode(state, action) {
            state.mode = action.payload
        },
        setNavBarVisible(state, action) {
            state.visible = action.payload;
        },
        setNavBarActiveIndex(state, action) {
            state.activeIndex = action.payload
        },
        setSideMenuOpen(state, action) {
            const sideMenu = { ...state.sideMenu, open: action.payload };
            if (!action.payload) {
                sideMenu.docked = true;
            }

            state.sideMenu = sideMenu;
            
        },
        setSideMenu(state, action) {
            state.sideMenu = action.payload
        },
        setLayoutMode(state, action) {

            state.mode = action.payload;

        }
    }
});

export const { setHeaderMode, setNavBarVisible, setNavBarActiveIndex, setBreadcrumbs, setSideMenuOpen, setSideMenu, setLayoutMode} = navBarSlice.actions;
export default navBarSlice.reducer;