import { ApiService } from "./api-service";
import { SettingsService } from "./settings.service";

export interface VersionSettings {
    version: string;
    releaseDate: string;
}

export class ServerVersionService {

    public async load(): Promise<VersionSettings> {
        let ss = new SettingsService();
        if (ss.production) {
            const rep = await fetch('/api/settings?json=true', { method: 'GET' });
            let text = await rep.text();
            let data = await ApiService.deserialize(text);
            if (data && data.version) {
                let ver = data.version;
                let date = new Date(data.releaseDate);

                return {
                    version: ver,
                    releaseDate: date.toJSON()
                }
            }
        }
        return {
            version: 'DEBUG',
            releaseDate: (new Date()).toJSON()
        }
    }

}