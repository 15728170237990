
import { format, formatDuration, parseISO } from 'date-fns';
import { zeroPadString } from './utils/formatters';
export const regexIso8601 = /(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})([.\d]*)Z/;

export const reviveDateTime = (key: any, value: any) => {
  var match;
  if (typeof value === "string" && (match = value.match(regexIso8601))) {
    var milliseconds = Date.parse(match[0]);
    if (!isNaN(milliseconds)) {
      return new Date(milliseconds);
    }
  }
  return value;
}

function isIsoDateString(value: any): boolean {
  return value && typeof value === "string" && regexIso8601.test(value);
}

export function handleDates(body: any) {
  if (body === null || body === undefined || typeof body !== "object")
    return body;

  for (const key of Object.keys(body)) {
    const value = body[key];
    if (isIsoDateString(value)) {
      var milliseconds = Date.parse(value);
      if (!isNaN(milliseconds)) {
        body[key] = new Date(milliseconds);
      }
    }
    else if (typeof value === "object") handleDates(value);
  }
}


export function formatDate(date?: Date | string, dateOnly?: boolean): string {
  if (date) {
    let value: Date;
    if (typeof date === 'string') {
      value = parseISO(date)
    } else {
      value = date;
    }
    if (!dateOnly)
      return format(value, 'yyyy-MM-dd HH:mm:ss');
    else
      return format(value, 'yyyy-MM-dd');
  }
  return '';
}


export function humanizeDuration(seconds: number): string {
  let duration = {
    seconds: seconds
  };
  return formatDuration(duration);
}

export function formatDurationAsTime(value: number): string {


  let hours = Math.floor(value / 60);
  let minutes = Math.floor(value - (hours * 60));
  let seconds = ((value - minutes - (hours * 60)) * 60).toFixed(2);

  return `${zeroPadString(2, hours)}:${zeroPadString(2, minutes)}:${zeroPadString(2, seconds)}`
}

export function datesToString(obj: any): any {

  if (Array.isArray(obj)) {
    return obj.map(x => datesToString(x));
  }

  if (obj) {


    for (const [key, value] of Object.entries(obj)) {
      if (value instanceof Date) {
        obj[key] = value.toJSON();
      } else if (typeof value === 'object') {
        datesToString(value);
      }
    }

  }
  return obj;
}



export interface TimeRangeOptions {
  backOnly: boolean;
}


export const noneTimeRangeKey = 'none-key';


