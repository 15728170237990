import { createSlice } from "@reduxjs/toolkit";

type ToastPositionType = 'center' | 'top' | 'bottom' | 'left' | 'right' | 'top-center' | 'top-left' | 'top-right' | 'bottom-center' | 'bottom-left' | 'bottom-right';


export enum MessageType {
    Info = 'info',
    Warning = 'warn',
    Error = 'error',
    Success = 'success'
}


export interface MessageBoxState {
    messageType: MessageType;
    duration?: number ;
    message: string;
    sticky?: boolean;
    title: string;
    position: ToastPositionType,
    visible: boolean
}

export const initMessageBoxState: MessageBoxState = {
    messageType: MessageType.Info,
    duration: 2000,
    message: '',
    sticky: false,
    title: '',
    visible: false,
    position: 'top-right'
};

const messageBoxSlice = createSlice({
    name: 'message-box',
    initialState: initMessageBoxState,
    reducers: {
        showMessageBox(state, action) {
            state.message = action.payload.message;
            state.duration = action.payload.duration;
            state.messageType = action.payload.messageType;
            state.sticky = action.payload.sticky
            state.position = action.payload.position ? action.payload.position : 'top-right';
            state.visible = true;
        },
        hideMessageBox(state, action) {
            state.message = '';
            state.duration = 0;
            state.visible = false;
        }

        
    },

   
})

export const { showMessageBox, hideMessageBox } = messageBoxSlice.actions
export default messageBoxSlice.reducer