import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { EnvironmentVarService } from './services/env-var.service';
import { store } from './redux/store';
import { updateSettings } from './redux/settings-slice';
import { Provider } from 'react-redux';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const getEnvVars = async () => {
  let envServer = new EnvironmentVarService();
  let data = await envServer.load();

  store.dispatch(updateSettings(data));
  return data;
}


  root.render(

    <Provider store={store} >
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </Provider>
  );

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
