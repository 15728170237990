import { ConfigurationData, DataType } from "@liminil/registration-sdk-client";
import { store } from "../redux/store";
import { ApiSettings } from "../types/settings.types";
const piURL = localStorage.getItem("piInstance");

export type ConfigurationValue = string | number | boolean | string[];

export class SettingsService {

    private _production: boolean;
    private _settings: ApiSettings;

    constructor() {
        let isDev = process.env.REACT_APP_DEV_MODE?.trim() === "true";
        let nodeEnv = this.getEnvValue("NODE_ENV");
        this._production = nodeEnv === 'production' && !isDev;
        if (this.production) {
            this._settings = {
                authApi: "/api/ath/",
                wkfApi: "/api/wkf/",
                logApi: "/api/log/",
                whsApi: "/api/whs/",
                flsApi: "/api/fls/",
                runApi: "/api/run/",
                regApi: "/api/reg/",
                server: "/",
                wkfSocket: "/",  //the socket service sets the actual path.
                authSocket: "/",
            }
        } else {

            this._settings = {
                authApi: "http://localhost:6690/",
                server: "http://localhost:6600/",
                wkfApi: "http://localhost:6605/",
                whsApi: "http://localhost:4065/",
                logApi: "http://localhost:6620/",
                runApi: "http://localhost:4050/",
                flsApi: "http://localhost:6640/",
                regApi: "http://localhost:6630/",
                authSocket: 'http://localhost:6691/',
                wkfSocket: "http://localhost:6606/"
            }
           
        }
    }


    public getEnvValue(name: string, defaultValue?: string) {

        let values = store.getState().settings.vars;
        if (values) {
            let item = values.find(x => x.name === name);
            if (item && item.value)
                return item.value;
        }
        return defaultValue ? defaultValue : '';
    }

   
    public static getConfigurationDataValue(data: ConfigurationData[], settingName: string, defaultValue: ConfigurationValue): ConfigurationValue {
        if (data) {
            let item = data.find(x => x.settingName.toLowerCase() === settingName.toLowerCase());
            if (item) {
                switch (item.dataType) {
                    case DataType.Integer: return parseInt(item.value);
                    case DataType.Float: return parseFloat(item.value);
                    case DataType.Boolean: return item.value.toLowerCase() === 'true' || item.value.toLowerCase() === 't';
                    case DataType.StringArray: return item.value.split(",").map(x => x.trim())
                    default: return item.value;
                }
            }
        }
        return defaultValue;
    }

    public getConfigurationValue(settingName: string, defaultValue: ConfigurationValue): ConfigurationValue {
        let appState = store.getState();
        let data = appState.settings.config;
        return SettingsService.getConfigurationDataValue(data as any, settingName, defaultValue);
    }

    public get production() {
        return this._production;
    }

    public get jwtClientId() {

        if (this.production) {
            let value = this.getEnvValue("JWT_CLIENT_ID", "92a5826c-b798-49f4-a422-9dba7f497785");
            return value;
        }

        return "92a5826c-b798-49f4-a422-9dba7f497785"
    }

    public get theme() {
        // if (this.production) {
        let value = this.getConfigurationValue("application.theme", "Liminil");
        if (value)
            return value;
        // }

        return "Liminil"

    }


    public get authApi() {
        const piURL = localStorage.getItem("piInstance");
        // if (piURL) {
        //     return piURL + '/api/ath/';
        // }
        return 'https://pie-dev.liminil.net/api/ath/'
    }





    public get runApi() {
        return this._settings.runApi;
    }

    public get wkfApi() {
        const piURL = localStorage.getItem("piInstance");
        // if (piURL) {
        //     return piURL + '/api/wkf/';
        // }
        return 'https://pie-dev.liminil.net/api/wkf/'
    }

    public get logApi() {
        return this._settings.logApi;
    }

    public get flsApi() {
        return this._settings.flsApi;
    }

    public get regApi() {
        return this._settings.regApi;
    }

    public get whsApi() {
        const whURL = localStorage.getItem("whInstance");
        // if (whURL) {
        //     return whURL + '/api/whs/';
        // }
        return 'https://pie-dev.liminil.net/api/whs/'
    }

    public get server() {
        return this._settings.server;
    }

    public get wkfSocket() {
        return this._settings.wkfSocket;
    }

    public get authSocket() {
        return this._settings.authSocket;
    }

    public get loginModes() {

        //    return this.getConfigurationValue("application.login_modes", ["office", "user"]) as string[];
        return ["office", "user"]
    }


    public get azureClientId() {
        if (this.production) {
            let value = this.getEnvValue("AZURE_CLIENT_ID", "ee2bb3a3-254b-4174-b38a-f318de396fb4");
            return value;
        }

        return "ee2bb3a3-254b-4174-b38a-f318de396fb4"
    }

    public get azureAuthority() {
        if (this.production) {
            let value = this.getEnvValue("AZURE_TENANT_ID", "308a7b63-6655-4cbb-8b98-79470a71e999");
            return `https://login.microsoftonline.com/${value}`;

        }

        return "https://login.microsoftonline.com/308a7b63-6655-4cbb-8b98-79470a71e999"
    }





}